<template>
  <div>
    <div class="row">
      <div class="col-6">
        <!-- <b-form-group>
          <label>Data do Encaminhamento</label>
          <b-form-input
            :disabled="notAllowed"
            v-model="encaminhamento.dt_encaminhamento"
            readonly
            :state="validateState('dt_encaminhamento')"
            type="date"
          ></b-form-input>
        </b-form-group> -->
      </div>
    </div>

    <select-medico-especialidade
      @especialidade="encaminhamento.especialidade_encaminhado_id = $event"
      :disabledEspecialidade="notAllowed"
      :disabledMedico="notAllowed"
      @medico="encaminhamento.medico_encaminhado_id = $event"
      @medicoComplete="entidade = $event"
      :medico_id="encaminhamento.medico_encaminhado_id"
      :especialidade_id="encaminhamento.especialidade_encaminhado_id"
      :stateMedico="validateState('medico_encaminhado_id')"
      :stateEspecialidade="validateState('especialidade_encaminhado_id')"
    ></select-medico-especialidade>

    <div class="row">
      <div class="col">
        <b-form-group>
          <label>Obeservações <small>*Motivo do encaminhamento</small> </label>

          <b-form-textarea
            :disabled="notAllowed"
            :state="validateState('obs')"
            v-model="encaminhamento.obs"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <!-- <div v-if="!!solicitacao.id" class="row">
      <div class="col">
        <b-btn @click="callPrintExame">
          <i v-if="!solicitacao.printLoad" class="fas fa-print"></i>
          <b-spinner small v-if="solicitacao.printLoad"></b-spinner> Imprimir
          Exame</b-btn
        >
      </div>
    </div> -->
    <div
      class="row justify-content-center border rounded  p-2  m-2"
      v-if="!!encaminhamento.encaminhado && !!encaminhamento.id"
    >
      <h4 class="col-12 text-muted text-center mt-1">
        Paciente já encaminhado!
      </h4>
      <b-btn size="" variant="primary" class="col-6">
        Visualizar Horário
      </b-btn>
    </div>

    <div class="row" v-if="!user.isMedico && !!encaminhamento.id">
      <div class="col">
        <div class="border  p-2 rounded shadow-sm ">
          O médico <b>{{ encaminhamento.medicoAtendimento }}</b> solicitou um
          encaminhamento para o paciente <b>{{ paciente.razao_social }} </b>

          <div class="mt-3">
            <h5 class="text-center">
              Detalhes do Encaminhamento
            </h5>
            <div class="">
              <i class="fas fa-stethoscope"></i>
              <b> Especialidade Solicitada: </b>
              {{ encaminhamento.especialidadeEncaminhado }}
            </div>
            <div v-if="encaminhamento.medicoEncaminhado">
              <i class="fas fa-user-nurse"></i>
              <b> Médico Solicitado: </b>
              {{ encaminhamento.medicoEncaminhado }}
            </div>
            <div class="mt-2 d-flex justify-content-center">
              <b-btn variant="primary">
                <i class="fas fa-ambulance"></i> Encaminhar
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BotoesSalvarCancelar2
      v-if="showButtons && !notAllowed"
      @salvar="salvar"
      :saveDisabled="notAllowed"
      @cancelar="$emit('close')"
    />
  </div>
</template>

<script>
import moment from "moment";
import PacienteEncaminhamentosLib from "../../../../libs/PacienteEncaminhamentosLib";
import SelectMedicoEspecialidade from "../../../common/SelectMedicoEspecialidade.vue";
import BotoesSalvarCancelar2 from "../../../common/BotoesSalvarCancelar.vue";
import Validadores from "../../../../helpers/Validadores";

export default {
  props: {
    id: [Number, String],
    objectEdit: { type: [Object, null], default: null },
    paciente: Object,
    horario: Object,
    saveDirect: { type: Boolean, default: true },
    showButtons: {
      type: Boolean,
      default: false,
    },
    medicoAtendimentoId: [Number, String],
  },
  components: {
    SelectMedicoEspecialidade,
    BotoesSalvarCancelar2,
  },
  data() {
    return {
      encaminhamento: {
        dt_encaminhamento: moment().format("YYYY-MM-DD"),
        dt_encaminhado: null,
        paciente_id: this.paciente?.id || null,
        medico_atendimento_id: null,
        especialidade_encaminhado_id: null,
        medico_encaminhado_id: null,
        horario_atendimento_id: this.horario?.id || null,
        horario_encaminhado_id: null,
        obs: "",
      },

      user: this.$store.state.auth.user,
      hasErrors: [],
      entidade: null,
    };
  },
  methods: {
    async carrega() {
      this.loading = true;
      if (this.id) {
        let encaminhamento = (
          await PacienteEncaminhamentosLib.get({ id: this.id })
        )[0];
        this.encaminhamento = Object.assign(
          this.encaminhamento,
          encaminhamento
        );
        this.encaminhamento.dt_encaminhamento = moment(
          this.encaminhamento.dt_encaminhamento
        ).format("YYYY-MM-DD");
        this.encaminhamento.dt_encaminhado = this.encaminhamento.dt_encaminhado
          ? moment(this.encaminhamento.dt_encaminhado).format("YYYY-MM-DD")
          : "";
      } else if (this.objectEdit) {
        this.encaminhamento = Object.assign(
          this.encaminhamento,
          this.objectEdit
        );
      }
      this.loading = false;
    },
    setMedicoAtendimento() {
      if (this.user.isMedico) {
        this.encaminhamento.medico_atendimento_id = this.user?.entidade?.id;
      } else if (this.medicoAtendimentoId) {
        this.encaminhamento.medico_atendimento_id = this.medicoAtendimentoId;
      }
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);

      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state, message, value) {
      let idx = this.hasErrors.findIndex((x) => x.campo === campo);
      if (idx === -1) {
        this.hasErrors.push({ campo, value, state, message });
      } else {
        this.hasErrors[idx].state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();
      await this.$nextTick();

      this.addOrUpdateError(
        "dt_encaminhamento",
        !Validadores.string.IsNullOrEmpty(
          this.encaminhamento.dt_encaminhamento
        ),
        "Informe a data de encaminhamento.",
        this.encaminhamento.dt_encaminhamento
      );

      this.addOrUpdateError(
        "obs",
        !Validadores.string.IsNullOrEmpty(this.encaminhamento.obs),
        "Informe o motivo do encaminhamento.",
        this.encaminhamento.obs
      );

      this.addOrUpdateError(
        "especialidade_encaminhado_id",
        !!this.encaminhamento.especialidade_encaminhado_id,
        "Informe a especialidade que irá encaminhar o paciente.",
        this.encaminhamento.especialidade_encaminhado_id
      );

      this.addOrUpdateError(
        "paciente_id",
        !!this.encaminhamento.paciente_id,
        "Há algum problema com o paciente, verifique se o paciente está cadastrado corretamente e/ou contate o TI.",
        this.encaminhamento.paciente_id
      );

      this.addOrUpdateError(
        "medico_atendimento_id",
        !!this.encaminhamento.medico_atendimento_id,
        `${
          this.user.isMedico
            ? "Peça ajuda de alguma de alguma secretária, há algum problema com o seu cadastro."
            : "<b>Somente médicos</b> podem solicitar encaminhamento."
        }`
      );

      return {
        validations: this.hasErrors,
        success: !this.hasErrors.some((e) => e.state === false),
        messages: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>\n ${this.hasErrors
          .filter((x) => x.state === false)
          .map((error) => error.message)
          .reduce((it, vl) => {
            it += vl + " <hr />";
            return it;
          }, "")}`,
      };
    },
    async salvar() {
      this.$forceUpdate();
      await this.$nextTick();
      let validacao = await this.validar();
      console.log("validacao", validacao);
      if (!validacao.success) {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: validacao.messages,
        });
        return;
      }
      // console.log(this.insideVenda, this.solicitacao);
      if (this.saveDirect) {
        try {
          let result = await PacienteEncaminhamentosLib.store(
            this.encaminhamento
          );
          console.log("reuslt do encaminhamento: ", result);
          this.encaminhamento.id = result?.data?.data;
          if (result.status == 200 || result.status == 201) {
            this.$emit("salvou", this.encaminhamento);

            this.showToast("success", "Solicitação salva com sucesso!");
          }
        } catch (error) {
          console.log("erro ao inserir", error);
          this.$swal({
            title: "Houve um problema",
            html: `<b>Erro ao executar a operação</b>`,
            icon: "error",
          });
          //this.showToast("error", "Alguma coisa deu errado :/\n" + error, 4600);
        }
      } else {
        //console.log('AAAAAAAAAAAAAAAAAAAAAAA');

        this.$emit("salvou", this.encaminhamento);
      }
    },
  },
  created() {
    this.setMedicoAtendimento();
    this.carrega();
  },
  watch: {},
  computed: {
    notAllowed() {
      return Boolean(
        (this.user.isMedico &&
          this.user.entidade.id != this.encaminhamento.medico_atendimento_id) ||
          moment().isAfter(
            moment(this.encaminhamento?.dt_encaminhamento).add(1, "day")
          ) ||
          this.encaminhamento.id
      );
    },
  },
};
</script>

<style></style>
