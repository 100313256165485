<template>
  <div>
    
    <div class="container-fluid">
      <div class="p-5 text-center" v-if="loading">
        <b-spinner /><br />
        aguarde...
      </div>
      <div class="text-right">
        <b-button variant="primary" @click="novo">
          <i class="fa fa-plus" /> Solicitar Encaminhamento
        </b-button>
      </div>

      <EmptyState v-if="encaminhamentos.length == 0">
        <h3>
          Nenhum encaminhamento encontrado!
        </h3>
        <p>
          Clique em
          <b-button class="mx-1" variant="primary" @click="novo">
            <i class="fa fa-plus" /> Solicitar Encaminhamento
          </b-button>
          para solicitar.
        </p>
      </EmptyState>

      <div v-if="!loading" class="listaItens">
  
        <paciente-item-card
          v-for="(encaminhamento, index) in encaminhamentos"
          @click.native="editar(encaminhamento)"
          :class="{ used: encaminhamento.dt_encaminhado }"
          :key="`encaminhamento_${encaminhamento.id}_${index}`"
          :objeto="encaminhamento"
          :tipo="`Encaminhamento`"
        />
      </div>
      <b-modal id="modal-add-encaminhamento" hide-footer size="md" title="Solicitar Encaminhamento">
        <!-- <template #modal-footer="{cancel}">
          <b-btn variant="primary" @click="salvar">
            <i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn variant="secondary" @click="cancel">
            <i class="fas fa-ban"></i> Cancelar</b-btn
          >
        </template> -->
        <paciente-encaminhamentos-form
          ref="encaminhamentoForm"
          @salvou="onSalvou($event)"
          :id="id_edit"
          @close="close"
          :paciente="paciente"
          :horario="horario"
          showButtons
          :medicoAtendimentoId="medicoAtendimentoId"
        ></paciente-encaminhamentos-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import PacienteItemCard from "../PacienteItemCard.vue";
import PacienteEncaminhamentosForm from "./PacienteEncaminhamentosForm.vue";
import PacienteEncaminhamentosLib from "../../../../libs/PacienteEncaminhamentosLib";
import moment from "moment";
import EmptyState from "../../../common/EmptyState.vue";
export default {
  name: "PacienteEncaminhamento",
  components: { PacienteItemCard, PacienteEncaminhamentosForm, EmptyState },
  props: {
    paciente: Object,
    horario: Object,
    medicoAtendimentoId: [Number, String]
  },

  created() {
    this.carregaEncaminhamentos();
  },
  data() {
    return {
      loading: false,
  
      encaminhamentos: [],
      id_edit: null,
  
    };
  },
  watch: {},
  computed: {},
  methods: {
    novo() {
      this.id_edit = null;
      this.$bvModal.show("modal-add-encaminhamento");
    },
    salvar() {
      this.$refs.PacienteExameForm.salvar();
    },
    async onSalvou(evt) {
      if (typeof evt?.close != "boolean") evt.close = true;
      // console.log("evento: ", evt);

      if (evt.close) {
        this.$bvModal.hide("modal-add-encaminhamento");
      }
      await this.carregaEncaminhamentos();
    },
    close() {
      this.$bvModal.hide("modal-add-encaminhamento");
    },
    editar(encaminhamento) {
      this.id_edit = encaminhamento.id;
      this.$bvModal.show("modal-add-encaminhamento");
    },
    async carregaEncaminhamentos() {
      this.$forceUpdate();

      try {
        this.encaminhamentos = (
          await PacienteEncaminhamentosLib.get({
            paciente_id: this.paciente.id,
          })
        ).map((encaminhamento) => {
          return { ...encaminhamento, dt_encaminhamento: moment(encaminhamento.dt_encaminhamento).format("YYYY-MM-DD") };
        });

       
        await this.$nextTick();
      } catch (error) {
        console.log(error);
        // this.showToast("error", "Erro ao carregar pacientes!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
