import axios from "../utils/axios";

const get = async ({
  id = "",
  paciente_id = "",
  medico_atendimento_id = "",
  medico_encaminhado_id = "",
  especialidade_encaminhado_id = "",
  dt_encaminhamento = "",
  horario_atendimento_id = "",
  horario_encaminhado_id = "",
}) => {
  let result;
  await axios
    .get("pacientes/encaminhamentos", {
      params: {
        id,
        paciente_id,
        medico_atendimento_id,
        medico_encaminhado_id,
        especialidade_encaminhado_id,
        dt_encaminhamento,
        horario_atendimento_id,
        horario_encaminhado_id,
      },
    })
    .then((res) => {
      /**
         * @type {(Array<{})} Retorna um array de mensagens de erros.
         */
      result = res.data;
    })
    .catch((err) => {
      if (err.response.data) {
        /**
         * @throws {(Array<String>} Retorna um array de mensagens de erros.
         */
        throw err.response.data;
      } else {
        /**
         * @throws {(Error<String>} Retorna um erro.
         */
        throw new Error("Erro ao buscar encaminhamentos!");
      }
    });

  return result;
};

// const verificaCodReceita = async (cod) => {
//     await axios.get(`pacientes/receitas/codigo/${cod}`)
// }

const store = async (data) => {
  let result = await axios
    .post("pacientes/encaminhamentos", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao inserir encaminhamentos!");
      }
    });
  return result;
};

/**
 * Encaminha encaminhamento
 * @param {Object<{id, medico_encaminhado_id, horario_encaminhado_id,dt_encaminhado}>} encaminhamento
 */
const encaminhar = async (encaminhamento) => {
  let result = await axios
    .post("pacientes/encaminhamentos", encaminhamento)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao encaminhar encaminhamento!");
      }
    });
  return result;
};

const del = async (id) => {
  let result = await axios
    .delete(`pacientes/encaminhamentos/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data && result.data.error) {
        throw err?.response?.data?.error?.message;
      } else {
        throw new Error("Erro ao excluir encaminhamento!");
      }
    });
  return result;
};

export default {
  get,
  store,
  encaminhar,
  del,
};
